import React, { useMemo } from "react";
import DataGrid, {
  Column,
  Paging,
  HeaderFilter,
  Scrolling,
  MasterDetail,
  Button as GridButton,
} from "devextreme-react/data-grid";
import { Card, CardBody } from "@metronic/_partials/controls";
import axios from "axios";

import { saveAs } from "file-saver";
import { BookStore } from "../../Stores/BookStore";
import { descargaStore } from "@stores";
import { useSelector } from "react-redux";

const URL = process.env.REACT_APP_API_URL;

function DetailTemplate({ data: { key, data } }) {
  const Store = useMemo(() => {
    const filter = [["book_id", "=", key]];
    if(data.fecha_publicacion){
      filter.push(data.fecha_publicacion);
      
      if(data.fecha_cese){
        filter.push(data.fecha_cese);
      }
    }
    return descargaStore(filter)
  }, [key, data]);

  const pdfIconClick = (e) => {
    axios({
      url: `${URL}/api/regalia/pdf/${e.row.data.id}`,
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      saveAs(response.data);
    });
    e.event.preventDefault();
  };

  const calculateCellValue = (data) => {
    const id = data.id;
    return !id ? "No dispone de ventas para el período señalado." : "";
  };

  return (
    <>
      <Card>
        {/* <CardHeader></CardHeader> */}
        <CardBody>
          <DataGrid
            dataSource={Store}
            columnAutoWidth={true}
            showBorders={true}
            useIcons={true}
            remoteOperations={{ filtering: true }}
            noDataText="No dispone de ventas para el período señalado."
          >
            <Column
              dataField="fecha"
              caption="Período"
              dataType="date"
              format={"MMMM, yyyy"}
            />
            <Column calculateCellValue={calculateCellValue} alignment="right" />
            <Column type="buttons">
              <GridButton
                hint="PDF"
                className="descargar-pdf"
                cssClass={"descargar-pdf"}
                text="Descargar informe"
                visible={(e) => !!e.row.data.id}
                onClick={pdfIconClick}
              />
            </Column>
          </DataGrid>
        </CardBody>
      </Card>
    </>
  );
}

export function DescargaPage() {
  //const dataGrid = useRef(null);
  const role = useSelector(({ auth }) => auth.user.role);

  return (
    <>
      <Card>
        <CardBody>
          <DataGrid
            dataSource={BookStore}
            // keyExpr="id"
            showBorders={false}
            remoteOperations={false}
            // useIcons={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            // ref={dataGrid}
            wordWrapEnabled={true}
          >
            <Scrolling columnRenderingMode="virtual" />
            <HeaderFilter visible={false} />
            <Paging enabled={false} />

            <Column dataField="sku" caption="SKU" />
            <Column dataField="titulo" caption="Titulo" />
            <MasterDetail
              enabled={true}
              autoExpandAll={role === "autor"}
              component={DetailTemplate}
            />
          </DataGrid>
        </CardBody>
      </Card>
    </>
  );
}
