import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import axios from "axios";
import { curry } from "lodash";

const ApiStoreMake = function(URL, filter) {

  function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
  }

  const Store = new CustomStore({
    filter: filter,
    key: "id",
    // onInserting: function(values) {
    //   values.book_id = key;
    // },
    load: function(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
        "searchExpr", 
        "searchOperation", 
        "searchValue"
      ].forEach(function(i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });

      params = params.slice(0, -1);

      return axios
        .get(`${URL}${params}`)
        .then((response) => {
          return {
            data: response.data.data,
          };
        })
        .catch(() => {
          throw new Error("Data Loading Error");
        });
    },
    insert: (values) => axios.post(`${URL}`, values),
    update: (key, values) => axios.put(`${URL}/${key}`, values),
    remove: (key) => axios.delete(`${URL}/${key}`),
    byKey: (key) =>
      axios.get(`${URL}/${key}`).then((response) => {
        return response.data.data;
      }),
  });

  const DS = new DataSource({
    store: Store,
    filter: filter,
  });

  return DS;
}

export const ApiStore = curry(ApiStoreMake);
