
import React from "react";

export function CheckListItem({ value, regex, children}) {
    let regexTest = new RegExp(regex);
    let testResult = regexTest.test(value);
    let okClass = (testResult)?"fa-check text-success":"fa-times text-danger";

    return (
        <>
            <li className="list-group-item w-100">
                <div className="d-flex w-100 justify-content-between">
                    <span className="mb-1 text-muted">{children}</span>
                    <i className={`fa ${okClass}`}/>
                </div>
            </li>    
        </>
    )
}