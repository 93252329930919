/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useEffect, useState} from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import {useSelector} from "react-redux";
import { bookStoreFilter } from "@stores";

export function QuickUser() {
  const history = useHistory();
  const {user} = useSelector(state => state.auth);

  const [bookList, setBookList] = useState([]);

  useEffect(() =>
  {
    const book = bookStoreFilter(["user_id", "=", user.id]);
    book.load({})
    .then(
        (data) => { setBookList(data) },
        (error) => { /* Handle the "error" here */ }
    )},[user.id]);
  
  const logoutClick = () => {
      const toggle = document.getElementById("kt_quick_user_toggle");
      if (toggle) {
        toggle.click();
      }
      history.push("/logout");
  };

  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            Mi perfil
          </h3>
          <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div
            className="offcanvas-content pr-5 mr-n5"
        >
          <div className="d-flex align-items-center mt-5">
            {/* <div
                className="symbol symbol-100 mr-5"
            >
              <div className="symbol-label" style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/default.jpg"
                )})`
              }}/>
              <i className="symbol-badge bg-success"/>
            </div> */}
            <div className="d-flex flex-column">
              <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {`${user.last_name}, ${user.name}`}
              </a>
              <div className="text-muted mt-1">{user.role}</div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.email}
                  </span>
                </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Salir</button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>

          <div>
            <h5 className="mb-5">Libros</h5>

            {bookList.map((book) => {
              return (  <div key={`${book.id}`} className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
                          <span className="svg-icon svg-icon-warning mr-5">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                                className="svg-icon svg-icon-lg"
                            ></SVG>
                          </span>

                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                            >
                              {book.titulo}
                            </a>
                            {/* <span className="text-muted font-size-sm">{`Paginas ${book.paginas}`}</span> */}
                          </div>

                          <span className="font-weight-bolder text-warning py-1 font-size-lg">
                          
                          </span>
                        </div>)
            })}
          </div>
        </div>
      </div>
  );
}
