import React, { useState } from "react";
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Lookup,
  Position,
  Form,
  HeaderFilter,
  FilterRow,
  Button,
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { Card, CardBody } from "@metronic/_partials/controls";

import { MonedaStore } from "@stores/MonedaStore";
import { ventaWebStore, paisStore2, pagosStore, bookStore } from "@stores";

import {
  SimpleItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from "devextreme-react/form";

export const VentaWebPage = () => {
  const [index, setIndex] = useState(0);

  return (
    <>
      <Card>
        <CardBody>
          <DataGrid
            dataSource={ventaWebStore}
            showBorders={false}
            remoteOperations={{ filtering: true }}
            useIcons={true}
            onCellClick={(e) => {
              if (e.rowType === "data" && e.column.dataField === "nombre") {
                e.component.editRow(e.row.rowIndex);
              }
            }}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging enabled={false} />
            <Editing
              mode="popup"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            >
              <Popup
                title="Información de la Venta"
                showTitle={true}
              >
                <Position my="center" at="center" of={window} />
              </Popup>
              <Form>
                {/* <GroupItem caption="Contact Information"> */}
                <TabbedItem colCount={2} colSpan={2}>
                  <TabPanelOptions deferRendering={false} defaultSelectedIndex={index}/>
                  {/* selectedIndex={tab} onInitialized={(e)=>{
                    // var instance = e.component;
                    // instance.option("selectedIndex", 2);
                  }} */}
                  <Tab title="Pedido" colCount={2}>
                    <SimpleItem dataField="book_id" colSpan={2} />
                    <SimpleItem dataField="moneda_id" />
                    <SimpleItem dataField="precio" />
                    <SimpleItem dataField="cantidad" />
                    <SimpleItem dataField="fecha" />
                    <SimpleItem dataField="medio_pago_id" />
                    <SimpleItem dataField="status_pago" />
                    <SimpleItem dataField="status" />
                  </Tab>
                  <Tab title="Comprador" colCount={2}>
                    <SimpleItem dataField="nombre" colSpan={2} />
                    <SimpleItem dataField="pais_id" />
                    <SimpleItem dataField="dni" />
                    <SimpleItem dataField="email" />
                    <SimpleItem dataField="telefono" />
                    <SimpleItem
                      dataField="direccion"
                      editorType="dxTextArea"
                      editorOptions={{ height: 100 }}
                      colSpan={2}
                    />
                  </Tab>
                  <Tab title="Interno" colCount={2}>
                    <SimpleItem dataField="precio_neto" />
                    <SimpleItem dataField="precio_usd" />
                    <SimpleItem dataField="orden" />
                    <SimpleItem dataField="pedido" />
                    <SimpleItem dataField="Tracking" />
                    <SimpleItem dataField="impresion" />
                    <SimpleItem dataField="envio" />
                    <SimpleItem dataField="total" />
                  </Tab>
                </TabbedItem>
                {/* </GroupItem> */}
              </Form>
            </Editing>

            <Column
              visible={true}
              dataField="fecha"
              caption="Fecha"
              dataType="date"
            />
            <Column visible={true} dataField="nombre" />
            <Column
              visible={false}
              dataField="dni"
              caption="Documento de identidad"
            />
            <Column visible={false} dataField="email" />
            <Column visible={false} dataField="telefono" />

            <Column visible={false} dataField="precio_neto" />
            <Column visible={false} dataField="precio_usd" />
            <Column visible={false} dataField="orden" />

            <Column visible={false} dataField="pedido" />
            <Column visible={false} dataField="Tracking" />
            <Column
              visible={false}
              dataField="impresion"
              caption="Impresión (USD)"
              // setCellValue={prueba}
            />
            <Column visible={false} dataField="envio" caption="Envío (USD)" />
            <Column
              visible={false}
              dataField="total"
              caption="Total (USD)"
              allowEditing={false}
            />
            <Column visible={false} dataField="direccion" />

            <Column
              visible={true}
              allowHeaderFiltering={false}
              dataField="book_id"
              caption="Libro"
              width="30%"
            >
              <Lookup
                dataSource={bookStore.store()}
                valueExpr="id"
                searchExpr={["titulo", "sku", "user.last_name"]}
                displayExpr={(data) => {
                  return `${data?.sku} - ${data?.titulo} (${data?.user?.last_name})`;
                }}
              />
            </Column>

            <Column visible={true} dataField="cantidad" />

            <Column dataField="medio_pago_id" caption="Medio de pago">
              <Lookup
                dataSource={pagosStore.store()}
                valueExpr="id"
                displayExpr="nombre"
              />
            </Column>

            <Column
              visible={true}
              dataField="status_pago"
              caption="Estado del pago"
            >
              <Lookup
                dataSource={[
                  { id: "Pendiente", codigo: "Pendiente" },
                  { id: "Pagado", codigo: "Pagado" },
                  { id: "Anulado", codigo: "Anulado" },
                  { id: "Reembolsado", codigo: "Reembolsado" },
                ]}
                valueExpr="id"
                displayExpr="codigo"
              />
            </Column>

            <Column dataField="pais_id" caption="Pais">
              <Lookup
                dataSource={paisStore2.store()}
                valueExpr="id"
                displayExpr="nombre"
              />
            </Column>

            <Column dataField="moneda_id" caption="Moneda">
              <Lookup
                dataSource={MonedaStore}
                valueExpr="id"
                displayExpr="codigo"
              />
            </Column>

            <Column visible={true} dataField="precio" />

            <Column
              visible={true}
              dataField="status"
              // filterType = "include"
              // filterValues= {["Solicitado", "Enviado"]}
              //defaultFilterValues= {["Solicitado", "Enviado"]}
            >
              <Lookup
                dataSource={[
                  { id: "Solicitado", codigo: "Solicitado" },
                  { id: "Enviado", codigo: "Enviado" },
                  { id: "Entregado", codigo: "Entregado" },
                  { id: "Reembolsado", codigo: "Reembolsado" },
                ]}
                valueExpr="id"
                displayExpr="codigo"
              />
            </Column>
            <Column type="buttons">
              <Button
                text="My Command"
                icon="map"
                hint="My Command"
                onClick={(e) => {
                  setIndex(1);
                  e.component.editRow(e.row.rowIndex);
                }}
              />
              <Button name="edit" />
              <Button name="delete" />
            </Column>
          </DataGrid>
        </CardBody>
      </Card>
    </>
  );
};
