import CustomStore from "devextreme/data/custom_store";
import axios from "axios";

//Url de acceso a la api
const URL = process.env.REACT_APP_API_URL;
const END_POINT = 'api/moneda';

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export const MonedaStore = new CustomStore({
  key: "id",
  //loadMode:"raw",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
      "searchExpr", 
      // "searchOperation", 
      "searchValue"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });

    params = params.slice(0, -1);

    return axios
      .get(`${URL}/${END_POINT}${params}`)
      .then((response) => {
        return {
          data: response.data.data,
        };
      })
      .catch(() => {
        throw new Error("Data Loading Error");
      });
  },
  insert: (values) => axios.post(`${URL}/${END_POINT}`, values),
  update: (key, values) => axios.put(`${URL}/${END_POINT}/${key}`, values),
  remove: (key) => axios.delete(`${URL}/${END_POINT}/${key}`),
  byKey: (key) => axios.get(`${URL}/${END_POINT}/${key}`).then((response) => {
    return response.data.data;
  })
});