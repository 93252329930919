import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";

const navigationConfig = [
    {
      title: "Dashboard",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />,
      permissions: ["admin"],
      navLink: "/moneda"
    },
    {
      title: "Pagos",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Thumbtack.svg")} />,
      permissions: ["admin"],
      navLink: "/sello-editorial"
    }
];

export default navigationConfig;