import { ApiStore } from "./ApiStore";

const URL = process.env.REACT_APP_API_URL;

export const paisStore = ApiStore(`${URL}/api/pais`);
export const monedaStore = ApiStore(`${URL}/api/moneda`, null);
export const libroPrecioStore = ApiStore(`${URL}/api/libro-precio`);
export const regaliaStoreFilter = ApiStore(`${URL}/api/regalia`);
export const descargaStore = ApiStore(`${URL}/api/descarga`);

export const SelloEditorialStore = ApiStore(`${URL}/api/sello-editorial`, undefined);
export const UserStore = ApiStore(`${URL}/api/auth`, null);
export const pagosStore = ApiStore(`${URL}/api/pagos`, undefined);
export const ventaWebStore = ApiStore(`${URL}/api/venta-web`, undefined);
export const bookStore = ApiStore(`${URL}/api/book`, undefined);
export const bookStoreFilter = ApiStore(`${URL}/api/book`);
export const paisStore2 = ApiStore(`${URL}/api/pais`, undefined);