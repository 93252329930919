import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";

const navigationConfig = [
    {
      title: "Monedas",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />,
      permissions: ["admin"],
      navLink: "/admin/moneda"
    },
    {
      title: "Países",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Flag.svg")} />,
      permissions: ["admin"],
      navLink: "/admin/pais"
    },
    {
      title: "Medios de pagos",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Calculator.svg")}/>,
      permissions: ["admin"],
      navLink: "/admin/pagos"
    },
    {
      title: "Sellos editoriales",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Thumbtack.svg")} />,
      permissions: ["admin"],
      navLink: "/admin/sello-editorial"
    },
    {
      title: "Libros",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />,
      permissions: ["admin"],
      navLink: "/admin/book"
    },
    {
      title: "Plataformas",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>,
      permissions: ["admin"],
      navLink: "/admin/Plataforma"
    },
    {
      title: "Usuarios",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")}/>,
      permissions: ["admin"],
      navLink: "/admin/usuarios"
    },
    {
      type: "groupHeader",
      title: "Ventas",
      permissions: ["admin"],
    },
    {
      title: "Ventas Web",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}/>,
      permissions: ["admin"],
      navLink: "/ventas-web"
    },
    {
      title: "Ventas manual",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}/>,
      permissions: ["admin"],
      navLink: "/ventas/manual"
    },
    {
      title: "Ventas Excel",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}/>,
      permissions: ["admin"],
      navLink: "/ventas/excel"
    },
    {
      type: "groupHeader",
      title: "Calculos",
      permissions: ["admin"],
    },
    {
      title: "Consolidado Regalías",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Calculator.svg")}/>,
      permissions: ["admin"],
      navLink: "/Regalias"
    },
    {
      title: "Resumen de ventas",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")}/>,
      permissions: ["admin"],
      navLink: "/grafico"
    },
    {
      type: "groupHeader",
      title: "Autores",
      permissions: ["admin", "autor"],
    },
    {
      title: "Inicio",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}/>,
      permissions: ["autor"],
      navLink: "/bienvenida"
    },
    {
      title: "Mi perfil",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>,
      permissions: ["admin", "autor"],
      navLink: "/perfil"
    },
    {
      title: "Reportes",
      type: "item",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-download.svg")}/>,
      permissions: ["admin", "autor"],
      navLink: "/descargas"
    },
    
];

export default navigationConfig;