import React from "react";

export function MenuGroupHeader({title, icon}) {

    return (
        <>
            {/* begin::section */}
            <li className="menu-section ">
            <h4 className="menu-text">{title}</h4>
            <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
        </>
    );
}