/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {checkIsActive} from "../../../../_helpers";

import { MenuGroupHeader } from "./MenuGroupHeader";
import { MenuItem } from "./MenuItem";

export function AsideMenuList({ layoutProps, config }) {
  const location = useLocation();
  const {user} = useSelector(state => state.auth);

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

          {config?.map((item)=>{
            if(item.type === "item"){
              if(item.permissions?.includes(user.role)){
                return (<MenuItem key={item.navLink} id={item.id} isActive={checkIsActive(location, item.navLink)} title={item.title} navLink={item.navLink} icon={item.icon}/>);
              } else {
                return null;
              }
            }
            
            if(item.permissions?.includes(user.role)){
              return (<MenuGroupHeader key={Math.random().toString()} title={item.title}/>);
            } else {
              return null;
            }
            
          })}
        </ul>
        {/* end::Menu Nav */}
      </>
  );
}
