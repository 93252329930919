import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/api/auth/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/api/auth/signup`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/auth/forgot-password`;
export const SEND_WELLCOME_EMAIL = `${process.env.REACT_APP_API_URL}/api/auth/wellcome-email`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/api/auth/user`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/auth/reset-password`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function sendWellcomeEmail(email) {
  return axios.post(SEND_WELLCOME_EMAIL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function resetPassword(token, email, password, password_confirmation) {
  return axios.post(RESET_PASSWORD_URL, { token, email, password, password_confirmation });
}
