import React from "react";
import {NavLink}  from "react-router-dom";

export function MenuItem({title, icon, navLink, isActive, id}) {

    return (
        <>
            {/*begin::1 Level*/}
          <li
              className={`menu-item ${isActive && "menu-item-active menu-item-open"} `}  // ${!isActive && "menu-item-active"} menu-item-open
              id={id}
          >
            <NavLink className="menu-link" to={navLink}>
            <span className="svg-icon menu-icon">
              {icon}
            </span>
              <span className="menu-text">{title}</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
        </>
    );
}