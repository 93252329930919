import React, {Suspense, lazy, useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import {BienvenidaPage} from "./modules/Admin/pages/BienvenidaPage";
import {MiPerfil} from "./modules/Admin/pages/MiPerfil";
import {DescargaPage} from "./modules/Descargas";
import {useSelector} from "react-redux";

import esMessages from "devextreme/localization/messages/es.json";
 
import { locale, loadMessages } from "devextreme/localization";
import { VentaWebPage } from "./modules/Ventas/pages/VentasWebPage";

const VentaPage = lazy(() =>
  import("./modules/Ventas")
);

const RegaliasPage = lazy(() =>
  import("./modules/Regalias")
);

const AdminPage = lazy(() =>
  import("./modules/Admin")
);

const Graficos = lazy(() =>
  import("./modules/Dashboard")
);

export default function BasePage() {
    useEffect(() => {
        loadMessages(esMessages);
        locale('es');
    }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    const {user} = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                  user.role === "admin" && <Redirect exact from="/" to="/admin/book"/>
                }
                {
                  user.role !== "admin" && <Redirect exact from="/" to="/bienvenida"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/bienvenida" component={BienvenidaPage}/>
                <ContentRoute path="/perfil" component={MiPerfil}/>
                <ContentRoute path="/descargas" component={DescargaPage}/>
                
                <Route path="/ventas-web" component={VentaWebPage}/>
                <Route path="/ventas" component={VentaPage}/>
                <Route path="/regalias" component={RegaliasPage}/>
                <Route path="/admin" component={AdminPage}/>
                <Route path="/grafico" component={Graficos}/>
                <Redirect to="error/error-v2"/>
            </Switch>
        </Suspense>
    );
}
