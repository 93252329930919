export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    function(response) {      
      return response;
    },
    function(error) {
      if (401 === error.response.status) {
        // En caso de un error de no auyenticado se va a la pagina de logout
        window.location.href = '/logout'; 
      } else {
        return Promise.reject(error);
      }
    }
  );
}
