/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import NewPassword from "./NewPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-4 login-signin-on d-flex flex-row-fluid"
        id="kt_login"
      >
        {/* {style="background-image: url('assets/media/bg/bg-3.jpg');"} */}
        <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`
        }}
        >
          <div className="login-form text-center p-7 position-relative overflow-hidden">
            {/*begin::Login Header*/}
            <div className="d-flex flex-center mb-15">
              <a href="#">
                <img
                  src={toAbsoluteUrl("/media/logos/logo-igneo.png")}
                  className="max-h-75px"
                  alt=""
                />
              </a>
            </div>

            {/*begin::Content*/}
            {/* begin::Content body */}
            <Switch>
              <ContentRoute path="/auth/login" component={Login} />
              <ContentRoute
                path="/auth/registration"
                component={Registration}
              />
              <ContentRoute
                path="/auth/forgot-password"
                component={ForgotPassword}
              />
              <ContentRoute
                path="/auth/reset-password/:token"
                component={NewPassword}
              />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
            {/*end::Content body*/}
            {/*end::Content*/}
          </div>
        </div>
      </div>
    </div>
  );
}
