import React from "react";
import {useSelector} from "react-redux";
import {toAbsoluteUrl} from "@metronic/_helpers";


import { Card, CardBody } from "@metronic/_partials/controls";

export function BienvenidaPage() {
    const {user} = useSelector(state => state.auth);

    return (
      <Card>
        <CardBody className="">
        <div className="d-flex flex-center flex-column my-15">
            <img
                src={toAbsoluteUrl("/media/logos/IGNEO LOGO SIMPLE.png")}
                className="max-h-200px"
                alt=""
            />
            <h4 className="mt-15">Hola, {user.name} {user.last_name} </h4> 
            <h4> Bienvenid{(user.sexo === 'Hombre')?'o':'a'} a nuestra plataforma.</h4>
        </div> 
        </CardBody>
      </Card>
    );
}
