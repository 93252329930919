import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Link, useParams, useHistory } from "react-router-dom";
import { resetPassword } from "../_redux/authCrud";
import Swal from 'sweetalert2';
import { CheckListItem } from "./Components/CheckListItem";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  icon: 'success',
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default function NewPassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  const {
    control,
    setError,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm();

  const password = useWatch({
    control,
    name: "password", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "" // default value before the render
  });

  const onSubmit = async (data) => {

    setLoading(true);

    try {
      await resetPassword(data.token, data.email, data.password, data.password_confirmation)
      Toast.fire({
        icon: 'success',
        title: "Cambio de clave correcto."
      });

      history.push("/login");

    } catch (error) {
      if (error.response) {
        // Request made and server responded
        const listError = error.response.data.errors
        
        for(let key in listError){
          setError(key, {
            type: "server",
            message: listError[key],
          });
        }
      }

      Toast.fire({
        icon: "error",
        title: "Error al cambiar la clave"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-form login-forgot" style={{ display: "block" }}>
      <div className="text-center mb-10">
        <h3 className="font-size-h1">Cambio de contraseña</h3>
        <div className="text-muted font-weight-bold">
          Ingrese su correo electrónico y nueva contraseña
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" defaultValue={token} {...register("token", { required: true })} />
        {errors.token?.type === "server" ? (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            {errors.token.message}
          </div>
        </div>
        ) : null}
        <div className="form-group fv-plugins-icon-container mb-5">
          <input
            type="email"
            placeholder="Correo electrónico"
            className={`form-control h-auto form-control-solid py-4 px-8`}
            {...register("email", { required: true })}
          />
          {errors.email?.type === "required" ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Correo requerido</div>
            </div>
          ) : null}
          {errors.email?.type === "server" ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors.email.message}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container mb-5">
          <input
            type="password"
            placeholder="Contraseña"
            className={`form-control h-auto form-control-solid py-4 px-8`}
            {...register("password", { required: true })}
          />
          {errors.password?.type === "required" ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Contraseña requerida</div>
            </div>
          ) : null}
          {errors.password?.type === "server" ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors.password.message.map(item => (<div className="fv-help-block">{item}</div>))}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container mb-5">
          <input
            type="password"
            placeholder="Confirmar contraseña"
            className={`form-control h-auto form-control-solid py-4 px-8`}
            {...register("password_confirmation", { required: true })}
          />
          {errors.password_confirmation?.type === "required" ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Contraseña requerida</div>
            </div>
          ) : null}
          {errors.password_confirmation?.type === "server" ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors.password_confirmation.message}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group">
            <ul className="list-group">
              <CheckListItem value={password} regex={"^.{8,}$"}>Longitud mayor a 8</CheckListItem>
              <CheckListItem value={password} regex={"[A-Z]"}>Una letra mayúscula</CheckListItem>
              <CheckListItem value={password} regex={"[a-z]"}>Una letra minúscula</CheckListItem>
              <CheckListItem value={password} regex={"[0-9]"}>Un número</CheckListItem>
              <CheckListItem value={password} regex={/\p{Z}|\p{S}|\p{P}/u}>Un caracter especial</CheckListItem>
            </ul>
        </div>

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            id="kt_login_forgot_submit"
            type="submit"
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            disabled={isSubmitting}
          >
            <span>Guardar</span>
            {isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <Link to="/auth">
            <button
              type="button"
              id="kt_login_forgot_cancel"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancelar
            </button>
          </Link>
        </div>
      </form>
      
    </div>
  );
}
