import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {toAbsoluteUrl} from "@metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { bookStoreFilter } from "@stores";


import { Card, CardBody, CardHeader } from "@metronic/_partials/controls";

export function MiPerfil() {
    const history = useHistory();
    const {user} = useSelector(state => state.auth);

    const [bookList, setBookList] = useState([]);

    useEffect(() =>
    {
      const book = bookStoreFilter(["user_id", "=", user.id]);
      book.load({})
      .then(
          (data) => { setBookList(data) },
          (error) => { /* Handle the "error" here */ }
      )},[user.id]);
    
    const logoutClick = () => {
        const toggle = document.getElementById("kt_quick_user_toggle");
        if (toggle) {
          toggle.click();
        }
        history.push("/logout");
    };

    return (
      <Card>
        <CardHeader title="Mi perfil"/>
        <CardBody className="">
        <div>
        <div className="d-flex align-items-center mt-5">
            {/* <div
                className="symbol symbol-100 mr-5"
            >
              <div className="symbol-label" style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/default.jpg"
                )})`
              }}/>
              <i className="symbol-badge bg-success"/>
            </div> */}
            <div className="d-flex flex-column">
              <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {`${user.last_name}, ${user.name}`}
              </a>
              <div className="text-muted mt-1">{user.role}</div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.email}
                  </span>
                </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Salir</button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>
            <h5 className="mb-5">Libros</h5>

            {bookList.map((book) => {
              return (  <div key={`${book.id}`} className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
                          <span className="svg-icon svg-icon-warning mr-5">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                                className="svg-icon svg-icon-lg"
                            ></SVG>
                          </span>

                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <div
                                className="font-weight-normal text-dark-75 font-size-lg mb-1"
                            >
                              {book.titulo}
                            </div>
                            {/* <span className="text-muted font-size-sm">{`Paginas ${book.paginas}`}</span> */}
                          </div>

                          <span className="font-weight-bolder text-warning py-1 font-size-lg">
                            <span className="text-muted font-size-sm">{`Paginas ${book.paginas}`}</span>
                          </span>
                        </div>)
            })}
          </div>
        </CardBody>
      </Card>
    );
}
